<template>
  <div class="left side-menu">
    <div class="slimscroll-menu" id="remove-scroll">
      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <!-- Left Menu Start -->
        <ul class="metismenu" id="side-menu">
          <li class="menu-title">Menu</li>
          <li>
            <a href="#" class="waves-effect">
              <i class="icon-accelerator"></i
              ><span class="badge badge-success badge-pill float-right"
                >9+</span
              >
              <span> Dashboard </span>
            </a>
          </li>
           <li>
            <a href="javascript:void(0);" class="waves-effect"
              ><i class="fas fa-user-circle"></i
              ><span>
                Munícipios
                <span class="float-right menu-arrow"
                  ><i class="mdi mdi-chevron-right"></i
                ></span> </span
            ></a>
            <ul class="submenu">
              <li><router-link to="/add-municipe">Adicionar </router-link></li>
              <li><router-link to="/all-municipes">Todos Munícipios</router-link></li>
            </ul>
          </li>
          <li>
            <a href="javascript:void(0);" class="waves-effect"
              ><i class="fas fa-users"></i
              ><span>
                Inscritos
                <span class="float-right menu-arrow"
                  ><i class="mdi mdi-chevron-right"></i
                ></span> </span
            ></a>
            <ul class="submenu">
               <li><router-link to="/all-users">Todos Inscritos</router-link></li>
            </ul>
          </li>
        <!-- 
          <li>
            <a href="javascript:void(0);" class="waves-effect"
              ><i class="fas fa-building"></i
              ><span>
                Municipios
                <span class="float-right menu-arrow"
                  ><i class="mdi mdi-chevron-right"></i
                ></span> </span
            ></a>
            <ul class="submenu">
              <li><router-link to="/add-municipes">Adicionar </router-link></li>
              <li><router-link to="/all-municipes">Todos Municipios</router-link></li>
            </ul>
          </li> -->

 
        </ul>
      </div>
      <!-- Sidebar -->
      <div class="clearfix"></div>
    </div>
    <!-- Sidebar -left -->
  </div>
</template>

<script>
 export default {

 };
</script>
