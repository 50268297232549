<template>
  <footer class="footer">
    ©2021 Octavio
    <span class="d-none d-sm-inline-block">
      -ICT TECNOLOGIA</span
    >.
  </footer>
</template>

<script>
export default {};
</script>
