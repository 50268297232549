<template>
  <div class="container">
    <div class="row">
      <div class="col-xl-12 col-xxl-12 col-sm-12 mx-auto">
        <div class="card" style="margin-top: 5em">
          <div class="row">
            <div class="col-lg-12">
              <div class="card-body">
                <form action="#" method="post">
                  <!-- personal User data -->
                  <div v-show="firstInfoShow">
                    <h1 class="text-center mb-5">Dados Pessoais</h1>
                  </div>
                  <div v-show="firstInfoShow">
                    <div class="row">
                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Nome Completo*</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="userData.fullName"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Genero*</label>
                          <select
                            class="form-control"
                            v-model="userData.gender"
                          >
                            <option value="Gender">Genero</option>
                            <option value="Male">Masculino</option>
                            <option value="Female">Feminino</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Data de nascimento*</label>
                          <input
                            type="date"
                            class="form-control"
                            v-model="userData.dateBirth"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Profissão*</label>
                          <select
                            class="form-control"
                            v-model="userData.profession_id"
                          >
                            <option value="0">Selecione Profissão</option>
                            <option
                              :value="prof.idProf"
                              v-for="prof in professionData"
                              :key="prof.idProf"
                            >
                              {{ prof.designacaoProf }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Ocupação actual*</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="userData.current_profession"
                          />
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Formação académica*</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="userData.school_academic"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="form-row d-flex justify-content-end mt-4 mb-2">
                      <button
                        type="button"
                        v-on:click="handleSecondStep"
                        class="btn btn-primary mb-3"
                      >
                        Proximo
                      </button>
                    </div>
                  </div>

                  <div v-show="secondInfoShow">
                    <div>
                      <h1 class="text-center mb-5">Residência Actual</h1>
                    </div>
                    <div class="row">
                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label"
                            >Selecione o Provincia*</label
                          >
                          <select
                            class="form-control"
                            v-model="userData.province_id"
                            @change="ChangeProvince($event)"
                          >
                            <option value="0">Selecione o Provincia</option>
                            <option
                              :value="provinces.id"
                              v-for="provinces in provincesData"
                              :key="provinces.id"
                            >
                              {{ provinces.name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label"
                            >Selecione o Distrito*</label
                          >
                          <select
                            :disabled="loadDistricts"
                            class="form-control"
                            @change="getZones"
                            v-model="userData.district_id"
                          >
                            <option value="0">Selecione o Distrito</option>
                            <option
                              :value="district.id"
                              v-for="district in districtData"
                              :key="district.id"
                            >
                              {{ district.name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Zona*</label>
                          <select
                            :disabled="loadDistricts"
                            @change="getLocalities"
                            class="form-control"
                            v-model="userData.zone_id"
                          >
                            <option value="0">Selecione o zona</option>
                            <option
                              :value="zone.id"
                              v-for="zone in zoneData"
                              :key="zone.id"
                            >
                              {{ zone.zone_name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Localidade*</label>
                          <select
                            :disabled="loadDistricts"
                            @change="getCircles"
                            class="form-control"
                            v-model="userData.localite_id"
                          >
                            <option value="0">Selecione o localidade</option>
                            <option
                              :value="local.id"
                              v-for="local in localitiesData"
                              :key="local.id"
                            >
                              {{ local.localite_name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Circulo*</label>
                          <select
                            :disabled="loadDistricts"
                            @change="getCells"
                            class="form-control"
                            v-model="userData.circle_id"
                          >
                            <option value="0">Selecione o circulo</option>
                            <option
                              :value="circle.id"
                              v-for="circle in circleData"
                              :key="circle.id"
                            >
                              {{ circle.circle_name }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Célula*</label>
                          <select
                            :disabled="loadDistricts"
                            class="form-control"
                            v-model="userData.cell_id"
                          >
                            <option value="0">Selecione célula</option>
                            <option
                              :value="cell.id"
                              v-for="cell in cellData"
                              :key="cell.id"
                            >
                              {{ cell.cell_name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Bairro*</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="userData.neighborhood"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      class="form-row d-flex justify-content-between mt-4 mb-2"
                    >
                      <button
                        type="button"
                        v-on:click="handleBackStepOne"
                        class="btn btn-secondary mb-3"
                      >
                        Voltar
                      </button>

                      <button
                        type="button"
                        v-on:click="handleStepThree"
                        class="btn btn-primary mb-3"
                      >
                        Proximo
                      </button>
                    </div>
                  </div>

                  <div v-show="stepThreeInfo">
                    <div>
                      <h1 class="text-center mb-5">Contactos</h1>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Email*</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="userData.email"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6 col-md-12 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Contacto(Whatsapp)*</label>
                          <input
                            type="text"
                            class="form-control"
                            @keypress="numberOnly"
                            pattern="/^[0-9]+$/"
                            maxlength="9"
                            v-model="userData.contact"
                          />
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Contacto Alternativo</label>
                          <input
                            type="text"
                            class="form-control"
                            @keypress="numberOnly"
                            pattern="/^[0-9]+$/"
                            maxlength="9"
                            v-model="userData.alternative_contact"
                          />
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label"
                            >Classificação Social *</label
                          >
                          <select
                            class="form-control"
                            v-model="userData.social_id"
                          >
                            <option value="0">Selecione classificação</option>
                            <option
                              :value="clas.idCS"
                              v-for="clas in socialClassicationData"
                              :key="clas.idCS"
                            >
                              {{ clas.DescricaoSocial }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div
                      class="form-row d-flex justify-content-between mt-4 mb-2"
                    >
                      <button
                        type="button"
                        v-on:click="handleBackStepTwo"
                        class="btn btn-secondary mb-3"
                      >
                        Voltar
                      </button>

                      <button
                        type="button"
                        v-on:click="handleStepFour"
                        class="btn btn-primary mb-3"
                      >
                        Proximo
                      </button>
                    </div>
                  </div>

                  <div v-show="stepFourInfo">
                    <div>
                      <h1 class="text-center mb-5">Sonhos</h1>
                    </div>
                    <div class="row mb-2">
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group">
                          <label class="form-label"
                            >O que gostaria de fazer?*</label
                          >
                          <textarea
                            class="form-control"
                            v-model="userData.what_you_wish"
                          ></textarea>
                        </div>
                      </div>
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group">
                          <label class="form-label"
                            >O que gostaria de ter?*</label
                          >
                          <textarea
                            class="form-control"
                            v-model="userData.what_you_want_have"
                          ></textarea>
                        </div>
                      </div>
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group">
                          <label class="form-label"
                            >O que espera que o governo faca no teu bairro, zona
                            , ou no pais no geral?*</label
                          >
                          <textarea
                            class="form-control"
                            v-model="userData.what_you_want_govern_do"
                          ></textarea>
                        </div>
                      </div>
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group">
                          <label class="form-label"
                            >O que você faria se fizesse para do governo
                            ?*</label
                          >
                          <textarea
                            class="form-control"
                            v-model="userData.what_you_can_do"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div
                      class="form-row d-flex justify-content-between mt-4 mb-2"
                    >
                      <button
                        type="button"
                        v-on:click="handleBackStepThree"
                        class="btn btn-secondary mb-3"
                      >
                        Voltar
                      </button>

                      <button
                        type="button"
                        v-on:click="handleStepFive"
                        class="btn btn-primary mb-3"
                      >
                        Proximo
                      </button>
                    </div>
                  </div>
                  <div v-show="stepFiveInfo">
                    <div>
                      <h1 class="text-center mb-5">Dados de Acesso</h1>
                    </div>
                    <div class="row mb-2">
                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Username*</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="userData.username"
                          />
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Senha*</label>
                          <input
                            type="password"
                            class="form-control"
                            v-model="userData.password"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form-label">Confirmar Senha*</label>
                          <input
                            type="password"
                            class="form-control"
                            v-model="userData.confSenha"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      class="form-row d-flex justify-content-between mt-4 mb-2"
                    >
                      <button
                        type="button"
                        v-on:click="handleBackStepFour"
                        class="btn btn-secondary mb-3"
                      >
                        Voltar
                      </button>

                      <button
                        type="button"
                        v-on:click="handleSubmitButton"
                        class="btn btn-primary mb-3"
                      >
                        {{ loading == false ? "Criar" : "Criando..." }}
                      </button>
                    </div>

                    <span
                      >Nota:No <strong class="text-white">username</strong> use
                      um nome discreto, que servira de sua identificao no
                      plataforma. <br />Ex: (laranja, xx_best e etc)
                    </span>
                    <div
                      class="form-row d-flex justify-content-between mt-4 mb-2"
                    >
                      <div class="form-group">
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            v-model="agree"
                            id="basic_checkbox_1"
                          />
                          <label
                            class="custom-control-label"
                            for="basic_checkbox_1"
                            >Concorda com os nossa termos de
                            responsabilidades</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <div class="new-account mt-3 text-center">
                  <p>
                    Ja tem uma conta?
                    <router-link style="color: #0093dd" to="/login"
                      >Clique aqui</router-link
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="processador" v-if="loading == true">
      <div class="login">
        <img src="assets/images/loader.gif" alt="" /><br />

        <p>Processando...</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import bridge from "../../Api/bridge";
import toastr from "toastr";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      url: process.env.VUE_APP_API_URL,
      firstInfoShow: true,
      secondInfoShow: false,
      stepThreeInfo: false,
      stepFourInfo: false,
      stepFiveInfo: false,
      personalData: true,
      loadDistricts: true,
      schoolData: false,
      courseData: false,
      documentData: false,
      loading: false,
      deficientTypeDisabled: true,
      userData: {
        fullName: "",
        email: "",
        gender: "",
        birthDate: "",
        profession_id: "",
        social_id: "",
        current_profession: "",
        school_academic: "",
        contact: "",
        alternative_contact: "",
        province_id: "",
        district_id: "",
        zone_id: "",
        circle_id: "",
        localite_id: "",
        birthDate: "",
        cell_id: "",
        neighborhood: "",
        password: "",
        confSenha: "",
        permissao: "V",
        username: "",
        what_you_wish: "",
        what_you_want_have: "",
        what_you_want_govern_do: "",
        what_you_can_do: "",
      },
      agree: false,
      provincesData: [],
      districtData: [],
      DataCourse: [],
      zoneData: [],
      circleData: [],
      cellData: [],
      localitiesData: [],
      professionData: [],
      socialClassicationData: [],
    };
  },
  methods: {
    ...mapActions({
      signIn: "auth/login",
    }),

    handleSecondStep() {
      this.firstInfoShow = false;
      this.secondInfoShow = true;
    },

    handleBackStepOne() {
      this.secondInfoShow = false;
      this.firstInfoShow = true;
    },

    handleBackStepTwo() {
      this.secondInfoShow = true;
      this.stepThreeInfo = false;
    },

    handleStepThree() {
      this.secondInfoShow = false;
      this.stepThreeInfo = true;
    },

    handleStepFour() {
      this.stepThreeInfo = false;
      this.stepFourInfo = true;
    },

    handleBackStepThree() {
      this.stepThreeInfo = true;
      this.stepFourInfo = false;
    },

    handleStepFive() {
      this.stepFourInfo = false;
      this.stepFiveInfo = true;
    },

    handleBackStepFour() {
      this.stepFourInfo = true;
      this.stepFiveInfo = false;
    },

    clearInputs() {
      this.userData.alternative_contact = "";
      this.userData.contact = "";
      this.userData.district_id = "";
      this.userData.confSenha = "";
      this.userData.email = "";
      this.userData.password = "";
      this.userData.gender = "";
      this.userData.fullName = "";
      this.userData.province_id = "";
      this.userData.username = "";
    },

    async handleSubmitButton() {
      this.loading = true;
      if (this.userData.password == "" || this.userData.confSenha == "") {
        this.loading = false;

        return toastr.warning(
          "Por favor preecha os campos obrigatorios",
          "Opsssiiii"
        );
      }

      if (this.agree == false) {
        this.loading = false;

        return toastr.warning("Campo obrigatorio", "Opsssiiii");
      }

      if (
        this.userData.fullName == "" ||
        this.userData.gender == "" ||
        this.userData.email == "" ||
        this.userData.contact == "" ||
        this.userData.province_id == ""
      ) {
        this.loading = false;
        return toastr.warning(
          "Por favor preecha os campos obrigatorios",
          "Opsssiiii"
        );
      }

      this.couseData = true;
      await axios
        .post(this.url + "Candidate", this.userData)
        .then((response) => {
          console.log(response.data);
          this.loading = false;

          if (response.data.status == false) {
            toastr.error("Por favor verifique o username ou email!!");
          } else {
            localStorage.setItem("user", JSON.stringify(response.data));
            toastr.success("Bem vindo!! acedeu com sucesso");
            this.signIn(response.data);
            this.clearInputs();
            this.goToFirstForm();
          }

          // toastr.success(response.data.message);
        })
        .catch((response) => {
          this.loading = false;

          console.log(response);
        });

      return;
    },

    async getProfessions() {
      await axios
        .get(this.url + "profession")
        .then((response) => {
          console.log(response.data);

          this.professionData = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getSocialClassification() {
      await axios
        .get(this.url + "socialClassification")
        .then((response) => {
          // console.log(response);

          this.socialClassicationData = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getProvinces() {
      await axios
        .get(this.url + "provinces")
        .then((response) => {
          // console.log(response);

          this.provincesData = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async ChangeProvince(evt) {
      this.loadDistricts = true;
      console.log(evt.target.value);

      await axios
        .get(this.url + "district/" + evt.target.value)
        .then((response) => {
          // console.log(response);
          this.districtData = response.data;
          this.loadDistricts = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getZones(evt) {
      this.loadDistricts = true;
      console.log(evt.target.value);

      await axios
        .get(this.url + "zones/" + evt.target.value)
        .then((response) => {
          // console.log(response);
          this.zoneData = response.data;
          this.loadDistricts = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getLocalities(evt) {
      this.loadDistricts = true;
      console.log(evt.target.value);

      await axios
        .get(this.url + "localities/" + evt.target.value)
        .then((response) => {
          // console.log(response);
          this.localitiesData = response.data;
          this.loadDistricts = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getCircles(evt) {
      this.loadDistricts = true;
      console.log(evt.target.value);

      await axios
        .get(this.url + "circles/" + evt.target.value)
        .then((response) => {
          // console.log(response);
          this.circleData = response.data;
          this.loadDistricts = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getCells(evt) {
      this.loadDistricts = true;
      console.log(evt.target.value);

      await axios
        .get(this.url + "cells/" + evt.target.value)
        .then((response) => {
          // console.log(response);
          this.cellData = response.data;
          this.loadDistricts = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    numberOnly($event) {
      let keycode = $event.keyCode ? $event.keyCode : $event.which;

      if ((keycode < 48 || keycode > 57) && keycode !== 46) {
        $event.preventDefault();
      }
    },
  },

  created() {
    this.getProvinces();
    this.getProfessions();
    this.getSocialClassification();
  },
};
</script>

<style scoped>
.bg-image {
  filter: blur(3px);
  -webkit-filter: blur(3px);
  height: 100%;
}

.login {
  position: fixed;
  top: 40%;
  left: 47%;
  padding: auto;
  margin: auto;
}
.login img {
  width: 80px;
  height: 80px;
}

#processador {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 999;

  width: 100%;

  border-radius: 4px;
  height: 100%;
}

#processador {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: 100%;
  transition: height 0.2s linear;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
}

#processador {
  background-color: rgba(0, 0, 0, 0.3) !important;
  color: #fff;
}
</style>
