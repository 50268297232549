import axios from "axios";
import authHeader from "@/Api/AuthHeader";
const url = process.env.VUE_APP_API_URL;

export default {
  namespaced: true,

  async createProfilePic(FormData) {
    try {
      const response = await axios.post(url+"createProfilePic", FormData, {
        headers: authHeader(),
        "content-type": "multipart/form-data",
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

 

  async getMyPosts(id) {
    try {
      const res = await axios.get(url+"getMyPosts/"+id, {
        headers: authHeader(),
      });
      return res;
    } catch (error) {
      return error;
    }
  },

  async getUserData(){
    try {
        const res = await axios.get(url+"getUserData", {
          headers: authHeader(),
        });
        return res;
      } catch (error) {
        return error;
      }
  },

  async updateData(formData){

    try {
        const response = await axios.post(url+"updateData", formData, {
            headers: authHeader(),
            "content-type": "multipart/form-data",
          });
          return response.data;
    } catch (error) {
        return error;
        
    }
  },

  async deletePost(id) {

    console.log("store", id);
    try {
      const response = await axios.get(url+"deletePost/" + id, {
        headers: authHeader(),
      });
      return response;
    } catch (error) {
      return error;
    }
  },
  
  async user(){
    try {
        const res = await axios.get(url+"user", {
          headers: authHeader(),
        });
        return res;
      } catch (error) {
        return error;
      }
  },

  async getPostComents(id) {
    try {
      const res = await axios.get(url+"getPostComents/" + id, {
        headers: authHeader(),
      });
      return res;
    } catch (error) {
      return error;
    }
  },

  async getPostbyId(id) {
    try {
      const res = await axios.get(url+"showPost/" + id, {
        headers: authHeader(),
      });
      return res;
    } catch (error) {
      return error;
    }
  },
};
