import axios from "axios";
import authHeader from "@/Api/AuthHeader";
const url = process.env.VUE_APP_API_URL;

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
     

   async getAllUsers() {

        try {
            
           const resp = await axios.get(url+"subscribes", { headers: authHeader() })
           return resp;

         } catch (error) {

            return error;
        }
     
    },
 
  },
};
