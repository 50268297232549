<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title">
          <h4>Perfil</h4>
        </div>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="index.html">Inicio</a></li>
          <li class="breadcrumb-item">
            <a href="javascript:void(0);">Admin</a>
          </li>
          <li class="breadcrumb-item active">
            <a href="javascript:void(0);">Perfil</a>
          </li>
        </ol>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Detalhes dos Munipios</h4>
        </div>
        <div class="card-body">
          <form @submit.prevent="submitFormData">
          
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Nome Completo*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="userData.fullName"
                  />
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Genero*</label>
                  <select class="form-control" v-model="userData.gender">
                    <option value="Gender">Genero</option>
                    <option value="Male">Masculino</option>
                    <option value="Female">Feminino</option>
                  </select>
                </div>
              </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Email/Contact*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="userData.email"
                  />
                </div>
              </div>
            </div>
            <div class="row">
               
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Contacto Alternativo</label>
                  <input
                    type="text"
                    class="form-control"
                    
                    v-model="userData.contact_alternative"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Selecione o Provincia*</label>
                  <select
                    class="form-control"
                    v-model="userData.province_id"
                    @change="ChangeProvince($event)"
                  >
                    <option value="0">Selecione o Provincia</option>
                    <option
                      :value="provinces.id"
                      v-for="provinces in provincesData"
                      :key="provinces.id"
                    >
                      {{ provinces.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Permissao*</label>
                  <select
                    class="form-control"
                    v-model="userData.permissao"
                    @change="ChangeProvince($event)"
                  >
                    <option value="0">Selecione a Permissao</option>
                     <option value="A">Admin</option>
                     <option value="M">Municipe</option>
                     <option value="V">Visitante</option>
                   </select>
                </div>
              </div>
              <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Selecione o Distrito*</label>
                  <select
                    :disabled="loadDistricts"
                    class="form-control"
                    v-model="userData.district_id"
                  >
                    <option value="0">Selecione o Distrito</option>
                    <option
                      :value="district.id"
                      v-for="district in districtData"
                      :key="district.id"
                    >
                      {{ district.name }}
                    </option>
                  </select>
                </div>
              </div> -->
            </div>

            <div class="row mb-2">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Senha*</label>
                  <input
                    type="password"
                    class="form-control"
                    v-model="userData.senha"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Confirmar Senha*</label>
                  <input
                    type="password"
                    class="form-control"
                    v-model="userData.confSenha"
                  />
                </div>
              </div>
            </div>
            
            <div class="float-right">
                <button
                  type="submit"
                   class="btn btn-primary "
                >
                  {{ loading == false ? "Criar" : "Criando..." }}
                </button>

            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import MunicipeStoreModule from "./MunicipeStoreModule";
import toastr from 'toastr';
export default {
  setup() {
    const userData = {
      fullName: "Octavio",
      email: "octavio@gmail.com",
      gender: "Masculino",
      contact_alternative: "844737833",
      province_id: "1",
      district_id: "",
      permissao:'',
      senha:'',
      confSenha:''
    };
      const loading =ref(false);
    const provincesData = ref([]);
    // const districtData = ref([]);

    async function submitFormData() {
            loading.value = true;

        if(userData.fullName == "" || userData.email == "" || userData.gender == "" || userData.province_id == "" || userData.permissao == ""
        || userData.senha == "" || userData.confSenha == ""){

            toastr.warning("Por favor preencha todos compos obrigatios...");
            loading.value = false;
            return
        }

        if (userData.senha != userData.confSenha) {
            toastr.warning("Por favor confirme a senha...");
            loading.value = false;
            return
        }
         const res = await MunicipeStoreModule.createMunicipe(userData);
         if(res.errors){
            toastr.info("O email ja existe tente outro")
            loading.value = false;

         }else{     
             toastr.success(res.message)
            loading.value = false;
            clearFields();

         }
     }

    function clearFields(){

        userData.fullName = "";
        userData.email = "";
        userData.confSenha = "";
        userData.senha = "";
        userData.contact_alternative = "";
        userData.gender = "";
        userData.permissao = "";
        userData.province_id = "";

    }

    async function getProvinces() {
        
        const res = await MunicipeStoreModule.getProvincias();
        provincesData.value = res;
        console.log(res);
    }

    function ChangeProvince(evt) {
      //   loadDistricts = true;
      //   console.log(evt.target.value);
      //   await axios
      //     .get("/api/district/" + evt.target.value)
      //     .then((response) => {
      //       // console.log(response);
      //       districtData.value = response.data;
      //       loadDistricts = false;
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //     });
    }

    function numberOnly($event) {
      let keycode = $event.keyCode ? $event.keyCode : $event.which;

      if ((keycode < 48 || keycode > 57) && keycode !== 46) {
        $event.preventDefault();
      }
    }

    return {
      userData,
      ChangeProvince,
      numberOnly,
      getProvinces,
      submitFormData,
      loading,
      provincesData,
      clearFields
    };
  },

  created() {
    this.getProvinces();
  },
};
</script>