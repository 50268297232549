import axios from "axios";
const BASE_API = "http://127.0.0.1:8000/api";
import toastr from "toastr";

export default {
  async callApi(method, url, dataObj) {
    try {
      return await axios({
        method: method,
        url: url,
        data: dataObj,
      });
    } catch (error) {
      return error.response;
    }
  },

  async signIn(email, password) {
    const req = await fetch(`${BASE_API}/Login`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    });

    const json = await req.json();

    // console.log(req);

    return json;
  },

  async addDept(dep_name, description, token) {
    const req = await fetch(`${BASE_API}/department`, {
      method: "Post",
      headers: {
        'Accept': "application/json",
        "Content-type": "application/json",
        'Authorization': "Bearer " + token,
      },
      body: JSON.stringify({ dep_name, description }),
    });

    const json = await req.json();

    // console.log(token);

    return json;
  },

  async getAllDept(token) {
    const req = await fetch(`${BASE_API}/department`, {
      method: "GET",
      headers: {
        'Accept': "application/json",
        "Content-type": "application/json",
        'Authorization': "Bearer " + token,
      },
    });

    const json = await req.json();

    // console.log(token);

    return json;
  },

  info(desc, title = "Hey") {
    toastr.info(desc, title);
  },

  success(desc, title = "Hey") {
    toastr.success(desc, title);
  },

  warning(desc, title = "Hey") {
    toastr.warning(desc, title);
  },

  error(desc = "Something went wrong! Please try again.", title = "Ooops") {
    toastr.error(desc, title);
  },
};
