<template>
  <div class="content">
    <div class="post" v-for="post in dataPost" :key="post.id">
      <div class="user-block">
        <img
          v-if="post.user.profile_pic == null"
          :src="imgUrl"
          alt=""
          class="img-circle img-bordered-sm"
        />
        <img
          v-else
          :src="Profilepath + post.user.profile_pic"
          alt=""
          class="img-circle img-bordered-sm"
        />
        <span class="username">
          <router-link
            :to="{ name: 'profile', params: { id: post.users_id } }"
            >{{ post.user.username }}</router-link
          >
          <!-- <a href="" class="float-right btn-tool"><i class="fas fa-times"></i></a> -->
        </span>
        <span class="description">
          Criado - {{ formatDates(post.created_at) }}
        </span>
      </div>
      <p v-if="post.description">
        <router-link :to="{ name: 'comments', params: { id: post.id } }">
          {{ post.description }}
        </router-link>
      </p>

      <div class="row mb-3">
        <div class="col-sm-12 col-lg-12">
          <router-link :to="{ name: 'comments', params: { id: post.id } }">
            <img
              v-if="post.img != null"
              :src="path + post.img"
              alt=""
              class="img-fluid"
            />
            <video
              v-if="post.video != null"
              :src="pathVideo + post.video"
              style="width: 100%"
              height="320"
              controls
            ></video>
            <embed
              v-if="post.doc_file != null"
              :src="pathDoc + post.doc_file"
              style="width: 100%"
              height="320"
              controls
            />
          </router-link>
        </div>
      </div>

      <p>
        <a
          href="javascript:void(0)"
          class="link-black text-sm mr-2"
          v-if="!likers(post.likes, post.id)"
          @click="like(post.id)"
          ><i class="fas fa-thumbs-up mr-1"></i> {{ post.likes.length }}
        </a>

        <a href="javascript:void(0)" class="link-black text-sm mr-2" v-else>
          <i class="fas fa-thumbs-up mr-1 text-primary"></i
          >{{ post.likes.length }}</a
        >

        <a
          href="javascript:void(0)"
          class="link-black text-sm mr-2"
          v-if="!dislikers(post.dislikes, post.id)"
          @click="dislike(post.id)"
        >
          <i class="fas fa-thumbs-down mr-1"></i>
          {{ post.dislikes.length }}</a
        >

        <a href="javascript:void(0)" class="link-black text-sm mr-2" v-else>
          <i class="fas fa-thumbs-down mr-1 text-danger"></i>
          {{ post.dislikes.length }}
        </a>

        <a href="" class="link-black text-sm mr-2"
          ><i class="fas fa-share mr-1"></i>Share
        </a>
        <span class="float-right">
          <router-link
            :to="{ name: 'comments', params: { id: post.id } }"
            class="link-black text-sm pr-4"
          >
            <i class="fas fa-comments mr-1"></i> Comentarios
            {{ post.comments.length }}
          </router-link>
        </span>
      </p>

      <!-- <Likes :id="post.id"></Likes> -->
    </div>

    <!-- <div class="post">
      <div class="user-block">
        <img :src="imgUrl2" alt="" class="img-circle img-bordered-sm" />
        <span class="username">
          <a href="">Cindy Jorge</a>
         </span>
        <span class="description"> Posted - 1 days ago </span>
      </div>

      <div class="row mb-3">
        <div class="col-sm-12">
          <img :src="imgUrlPost" alt="" class="img-fluid" />
        </div>
      </div>

      <p>
        <a href="" class="link-black text-sm mr-2"
          ><i class="fas fa-share mr-1"></i>Share</a
        >
         <span class="float-right">
          <router-link
            :to="{ name: 'comments', params: { id: 2 } }"
            class="link-black text-sm pr-4"
          >
            <i class="far-fa-comments mr-1"></i> Comentarios (5)
          </router-link>
        </span>
      </p>
    </div>
    <div class="post">
      <div class="user-block">
        <img :src="imgUrl3" alt="" class="img-circle img-bordered-sm" />
        <span class="username">
          <a href="">Maria Jones</a>
         </span>
        <span class="description"> Posted - 3 days ago </span>
      </div>
      <p>
        Lorem ipsum represents a long-held tradition for designers, typographers
        and the like. Some people hate it and argue for its demise, but others
        ignore the hate as they create awesome tools to help create filler text
        for everyone from bacon lovers to Charlie Sheen fans.
      </p>
      <p>
        <a href="" class="link-black text-sm mr-2"
          ><i class="fas fa-share mr-1"></i>Share</a
        >
         <span class="float-right">
          <router-link
            :to="{ name: 'comments', params: { id: 2 } }"
            class="link-black text-sm pr-4"
          >
            <i class="far-fa-comments mr-1"></i> Comentarios (5)
          </router-link>
        </span>
      </p>
    </div> -->
  </div>
</template>

<script>
import { mapActions } from "vuex";
import store from "@/store";
import Likes from "./Likes.vue";
import PostStoreModule from "./PostStoreModule";

export default {
  props: ["dataPost", "Datalikes"],
  components: {
    Likes,
  },

  data() {
    return {
      imgUrl: "https://bitwise.bitwise.co.mz/assets/images/avatar4.png",
      imgUrl2: "https://bitwise.bitwise.co.mz/assets/images/avatar2.png",
      imgUrl3: "https://bitwise.bitwise.co.mz/assets/images/avatar3.png",
      imgUrlPost: "https://bitwise.bitwise.co.mz/assets/images/photo4.jpg",
      path: "https://bitwise.bitwise.co.mz/bitwise/assets/upload_img/",
      // path: "http://127.0.0.1:8000/assets/upload_img/",
      // pathVideo: "http://127.0.0.1:8000/assets/upload_video/",
      pathVideo: "https://bitwise.bitwise.co.mz/bitwise/assets/upload_video/",
      // Profilepath: "http://127.0.0.1:8000/assets/upload_profile/",
      Profilepath:
        "https://bitwise.bitwise.co.mz/bitwise/assets/upload_profile/",
      pathDoc: "https://bitwise.bitwise.co.mz/bitwise/assets/upload_doc/",
      // pathDoc: "http://127.0.0.1:8000/assets/upload_doc/",

      user_id_logged: store.state.auth.user.id,
      likesPost: [],
      posters_id: [],
      user_id_logged: store.state.auth.user.id,
    };
  },

  computed: {
    posters() {
      // this.dataPost.forEach(element => {

      //   this.posters_id.push(element.likes[0]);

      // });

      for (let data of this.dataPost) {
        for (let l of data.likes) {
        }
      }

      return this.posters_id;
    },
  },
  methods: {
    likers(dataLikes, post_id) {
      if (this.user_id_logged && dataLikes) {
        for (let key of dataLikes) {
          if (
            key.users_id === this.user_id_logged &&
            key.posts_id === post_id
          ) {
            return true;
          }
        }
      }

      return false;
    },

    dislikers(dataLikes, post_id) {
      if (this.user_id_logged && dataLikes) {
        for (let key of dataLikes) {
          if (
            key.users_id === this.user_id_logged &&
            key.posts_id === post_id
          ) {
            return true;
          }
        }
      }

      return false;
    },

    formatDates(dateFormat) {
      const data = new Date(dateFormat);
      const day = data.getDate();
      const month = data.getMonth() + 1;
      const year = data.getFullYear();
      const hours = data.getHours();
      const minutes = data.getMinutes();
      const seconds = data.getSeconds();

      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    },

    // likers(post_id) {
    //   if (this.user_id_logged && this.Datalikes) {
    //     for (let key of this.Datalikes) {
    //       if (
    //         key.users_id === this.user_id_logged &&
    //         key.posts_id === post_id
    //       ) {
    //         return true;
    //       }
    //     }
    //   }

    //   return false;
    // },

    ...mapActions({
      LikedPost: "auth/likedPost",
    }),

    async getAllLIkesBypost(id) {
      const res = await PostStoreModule.getLikesByPostbyId(id);
      console.log("like" + res.data);
      this.LikesPost = res.data;
    },

    async getAllDislikesBypost() {
      const res = await PostStoreModule.getDislikesByPostbyId(this.id);

      this.DislikesPost = res.data;
    },

    async like(post_id) {
      const res = await PostStoreModule.createLike(post_id);
      this.LikedPost(true);
    },

    async dislike(post_id) {
      const res = await PostStoreModule.createDislike(post_id);
      this.LikedPost(true);
    },
  },
  created() {
    //  console.log("userid", this.dataPost);
  },
};
</script>

<style scoped>
.post {
  border-bottom: 1px solid #adb5bd;
  color: #666;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.post:last-of-type {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}
.post .user-block {
  margin-bottom: 15px;
  width: 100%;
}
.post .row {
  width: 100%;
}

.img-circle {
  border-radius: 50%;
}

.img-bordered-sm {
  border: 2px solid #adb5bd;
  padding: 2px;
}

.user-block {
  float: left;
}
.user-block img {
  float: left;
  height: 40px;
  width: 40px;
}
.user-block .comment,
.user-block .description,
.user-block .username {
  display: block;
  margin-left: 50px;
}
.user-block .username {
  font-size: 16px;
  font-weight: 600;
  margin-top: -1px;
}
.user-block .description {
  color: #6c757d;
  font-size: 13px;
  margin-top: -3px;
}

.link-black {
  color: #6c757d;
}
.link-black:focus,
.link-black:hover {
  color: #e6e8ea;
}
</style>