<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Dashboard</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inscritos</a></li>
          <li class="breadcrumb-item active">Lista de Inscritos</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-6">
              <h5 class="card-title">Lista de Inscritos</h5>
            </div>

           
          </div>
        </div>
        
        <div class="card-body">
          <div class="form-group float-right m-2">
            <form action="" role="search" class="app-search">
              <input
                type="text"
                class="form-control"
                placeholder="Pesquisar..."
              />
              <button type="submit"><i class="fa fa-search"></i></button>
            </form>
          </div>
          <div class="table-responsive">
            <table
              id="example5"
              class="table table-hover"
              style="min-width: 845px"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Username</th>
                  <th>Email</th>
                  <th>Accoes</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(c, index) in users" :key="index">
                  <td>{{ 1 + index }}</td>
                  <td>{{ c.username }}</td>
                  <td>{{ c.email }}</td>
                  <td>
                    <a
                       class="btn btn-sm btn-primary"
                      ><i class="fas fa-edit"></i
                    ></a>&nbsp;
                    <a
                       href="javascript:void(0);"
                      class="btn btn-sm btn-danger"
                      ><i class="fas fa-trash-alt"></i
                    ></a>
                  </td>
                </tr>
              </tbody>
            </table>             
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// import LaravelVuePagination from "laravel-vue-pagination";
 
import { ref, onUnmounted, onMounted } from "vue";
import store from "@/store";
import UserStoreModule from "./UserStoreModule";
import toastr from "toastr";

export default {
   
  setup() {
    const USER_MODULE_NAME = "User";

    if (!store.hasModule(USER_MODULE_NAME)) {
      store.registerModule(USER_MODULE_NAME, UserStoreModule);
    }

    onUnmounted(() => {
      if (store.hasModule(USER_MODULE_NAME)) {
        store.unregisterModule(USER_MODULE_NAME);
      }
    });

    const users = ref([]);
    const totalCourses = ref(null);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const perPage = ref(15);
    const searchQuery = ref("");
    const isDelete = ref(false);
    const index = ref(null);
    const course_id = ref();
    const isDeleting = ref(false);

    async function getAllsubscribes() {
            const resp = await UserStoreModule.actions.getAllUsers();
            users.value = resp.data.data;
            console.log(resp);
    }

    onMounted(()=>{
        getAllsubscribes()
    })

    // function isdeleteCourse(id, i) {
    //   console.log(id);
    //   isDelete.value = true;
    //   index.value = i;
    //   course_id.value = id;
    // }

    // async function deleteCourse() {
    //   isDeleting.value = true;
    //   // console.log(id_course);
    //   // const id = course_id.value
    //   const response = await CourseStoreModule.actions.deleteCourse(course_id.value)
    //       console.log(response);
    //   if (response) {
    //     course.value.data.splice(index.value, 1);
    //       isDeleting.value = false;
    //       isDelete.value = false;
        
    //   }
    //   // store
    //   //   .dispatch("Curso/deleteCourse", id_course.value)
    //   //   .then((response) => {
    //   //     console.log(response);
    //   //     course.value.data.splice(index.value, 1);
    //   //     isDeleting.value = false;
    //   //     isDelete.value = false;
    //   //   })
    //   //   .catch((error) => {
    //   //     console.log(error);
    //   //   });
    // }

    // function deleteCourse() {
    //   isDeleting.value = true;
    //   store
    //     .dispatch("Curso/deleteCourse", id_course.value)
    //     .then((response) => {
    //       console.log(response);
    //       course.value.data.splice(index.value, 1);
    //       isDeleting.value = false;
    //       isDelete.value = false;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // }
    // function closeModal() {
    //   isDelete.value = false;
    // }
    return {
      users,
      getAllsubscribes,
      perPage,
      currentPage,
       searchQuery,
      perPageOptions,
       
       };
  },
 
};
</script>


<style scope>
.modal {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: 100%;
  transition: height 0.2s linear;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
}

.modal {
  background-color: rgba(0, 0, 0, 0.3) !important;
  color: #fff;
}

.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 999;

  width: 100%;

  border-radius: 4px;
  height: 100%;
}
</style>