<template>
    
              <div class="row">
                <!-- accepted payments column -->
                <div class="col-6">
                  <p class="lead">Metodos de Pagamento:</p>
                  <img src="assets/images/credit/visa.png" alt="Visa">
                  <img src="assets/images/credit/mastercard.png" alt="Mastercard">
                  <img src="assets/images/credit/american-express.png" alt="American Express">
                  <img src="assets/images/credit/paypal2.png" alt="Paypal">

                  <p class="text-muted well well-sm shadow-none" style="margin-top: 10px;">
                    Etsy doostang zoodles disqus groupon greplin oooj voxy zoodles, weebly ning heekya handango imeem
                    plugg
                    dopplr jibjab, movity jajah plickers sifteo edmodo ifttt zimbra.
                  </p>
                </div>
                <!-- /.col -->
                <div class="col-6">
                  <p class="lead">Data 2/22/2014</p>

                  <div class="table-responsive">
                    <table class="table">
                      <tr>
                        <th style="width:50%">Valor:</th>
                        <td>100MT</td>
                      </tr>
                      <tr>
                        <th>Numero da Conta visa</th>
                        <td>11111111111111xxx</td>
                      </tr>
                      <tr>
                        <th>Numero da Conta Master Card</th>
                        <td>111111111111111xxx</td>
                      </tr>
                      <tr>
                        <th>Emola:</th>
                        <td>86000000</td>
                      </tr>
                       <tr>
                        <th>M-pesa:</th>
                        <td>84111111</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <!-- /.col -->
              </div>
</template>