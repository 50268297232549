<template>
  <h2>Hello</h2>
</template>
<script>
import store from "@/store";
import { ref, watch } from "vue";
import toastr from "toastr";
import CommentsStoreModule from "./CommentsStoreModule";
export default {
  setup() {
    const postData = {
      description: "",
      id: "",
      img: "",
    };
    const post_id = ref();

    async function getPostbyId() {
        post_id.value = this.$route.params.id;
        console.log( this.$route.params.id);
        const res = await CommentsStoreModule.getPostbyId(post_id.value);
        console.log(res);

    }


    return {
      getPostbyId,
      postData,
      post_id
    };
  },
  created(){
    this.getPostbyId();
  }
};
</script>