import axios from "axios";
// import router from "@/router";
export default {
  namespaced: true,
  state: {
    authenticated: false,
    user: {},
    userlikedPost:false,
    onlineUser: 0,
    provincia: "",
    userProfile:null,
    imgState:false
  },
  getters: {
    authenticated(state) {
      return state.authenticated;
    },
    user(state) {
      return state.user;
    },

    userlikedPost(state){
      return state.userlikedPost;
    },

    onlineUser(state){
      return state.onlineUser;
    },
    provinciaPosts(state){
      return state.provincia;
    },

    userProfile(){
      return state.userProfile;
    },

    img(){
      return state.imgState
    }
  },
  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },
    SET_USER(state, value) {
      state.user = value;
    },
    SET_USERLIKEDPOST(state, value){
      state.userlikedPost = value
    },
    SET_ONLINEUSER(state, value){
      state.onlineUser = value
    },

    SET_PROVINCIA(state, value){
      state.provincia = value
    },
    SET_PROFILE(state, value){
      state.userProfile = value
    },
    SET_IMG(state, value){
      state.imgState = value
    }
  },
  actions: {
    login({ commit }, data) {
      // console.log(access_oken)
          const url = process.env.VUE_APP_API_URL;

      axios
        .get(url+"user", {
          headers: {
            Authorization: "Bearer " + data.access_token,
          },
        })
        .then(({ data }) => {
          commit("SET_USER", data);
          commit("SET_AUTHENTICATED", true);
          // router.push({ name: "dashboard" });
          window.location.href = "/dashboard"
        })
        .catch(() => {
          commit("SET_USER", {});
          commit("SET_AUTHENTICATED", false);
        });
    },

    logout({ commit }) {
      commit("SET_USER", {});
      commit("SET_AUTHENTICATED", false);
    },

    likedPost({commit}, payload){
      commit("SET_USERLIKEDPOST", payload);
    },

    totalUserOnline({commit}, payload){
      commit("SET_ONLINEUSER", payload);
    },

    postProvincia({commit}, payload){
      commit("SET_PROVINCIA", payload);
    },

    profile({commit}, payload){
      commit("SET_PROFILE", payload);
    },

    updateUser({commit}, payload){
      commit("SET_USER", payload);

    },

    updateimgstate({commit}, payload){
      commit("SET_IMG", payload);
    }

  },
};
