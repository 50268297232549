import { createApp } from 'vue'
// import Home_main from './components/home_main.vue'
import AppMain from './AppMain.vue' 
import Router from '@/router'
import store from '@/store'
import toastr from 'toastr'
import axios from 'axios'
import VueAxios from 'vue-axios'
// import { VueSelect } from "vue-select/dist/vue-select.es";
import "vue-select/dist/vue-select.css";
import LaravelVuepagination from 'laravel-vue-pagination';
import { Bootstrap4Pagination } from 'laravel-vue-pagination';

axios.defaults.withCredentials = true;


// const app = createApp({});
// // app.component(Home_main)
// app.mount("#app")

const app = createApp(AppMain);
    // app.component("v-select", VueSelect)
    app.use(VueAxios, axios)
    app.use(Router)
    app.use(toastr)
    app.use(store)
    app.use(Bootstrap4Pagination);
    app.mount("#app");
