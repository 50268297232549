<template>
  <div class="topbar">
    <!-- LOGO -->
    <!-- <nav class="navbar-custom">
    <div class="topbar-left">
      <router-link to="/" class="logo " >
        <span class="d-block d-sm-none d-lg-block d-md-block rounded-circle">
          <img src="assets/images/Logo_Branco.png" class="text-danger" height="55" alt="" />
        </span>

       
      </router-link>
    </div>
       <div class="container">
        <ul class="navbar-right list-inline float-right mb-0">
        
 
          
          <li class="dropdown notification-list list-inline-item">
            <router-link
              class="nav-link dropdown-toggle arrow-none d-none d-sm-none d-lg-block d-md-block"
              to="/"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              Home
          </router-link>
          </li>

          <li class="dropdown notification-list list-inline-item">
            <router-link
              class="nav-link dropdown-toggle arrow-none waves-effect"
              to="/donate"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              Doar
          </router-link>
          </li>
          <li class="dropdown notification-list list-inline-item">
              <a v-if="totalUserOnline > 0"
                class="nav-link dropdown-toggle arrow-none d-none d-lg-block d-sm-none"
                href="javascript:void(0)"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
              <i class="fas fa-circle text-success"></i>
                Online: {{totalUserOnline}}
            </a>
            <a v-else
                class="nav-link dropdown-toggle arrow-none d-none d-lg-block d-sm-none"
                href="javascript:void(0)"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
              <i class="fas fa-circle text-danger"></i>
                Online: {{totalUserOnline}}
            </a>
          </li>

          <li class="dropdown notification-list list-inline-item">
            <a
              class="nav-link dropdown-toggle arrow-none waves-effect"
              href="javascript:void(0)"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              {{ provincia }}  
          </a>
            
          </li>
          
            <li class="dropdown notification-list list-inline-item">
            <a
              class="nav-link dropdown-toggle arrow-none waves-effect"
              data-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <i class="mdi mdi-bell-outline noti-icon"></i>
              <span class="badge badge-pill badge-danger noti-icon-badge">3</span>
            </a>
            <div
              class="dropdown-menu dropdown-menu-right dropdown-menu-animated dropdown-menu-lg px-1"
            >
              <h6 class="dropdown-item-text">Notifications</h6>
              <div class="slimscroll notification-item-list">
                <a
                  href="javascript:void(0);"
                  class="dropdown-item notify-item active"
                >
                  <div class="notify-icon bg-success">
                    <i class="mdi mdi-cart-outline"></i>
                  </div>
                  <p class="notify-details">
                    <b>Your order is placed</b
                    ><span class="text-muted"
                      >Dummy text of the printing and typesetting industry.</span
                    >
                  </p>
                </a>
                <a href="javascript:void(0);" class="dropdown-item notify-item">
                  <div class="notify-icon bg-danger">
                    <i class="mdi mdi-message-text-outline"></i>
                  </div>
                  <p class="notify-details">
                    <b>New Message received</b
                    ><span class="text-muted">You have 87 unread messages</span>
                  </p>
                </a>
                <a href="javascript:void(0);" class="dropdown-item notify-item">
                  <div class="notify-icon bg-info">
                    <i class="mdi mdi-filter-outline"></i>
                  </div>
                  <p class="notify-details">
                    <b>Your item is shipped</b
                    ><span class="text-muted"
                      >It is a long established fact that a reader will</span
                    >
                  </p>
                </a>
                <a href="javascript:void(0);" class="dropdown-item notify-item">
                  <div class="notify-icon bg-success">
                    <i class="mdi mdi-message-text-outline"></i>
                  </div>
                  <p class="notify-details">
                    <b>New Message received</b
                    ><span class="text-muted">You have 87 unread messages</span>
                  </p>
                </a>
                <a href="javascript:void(0);" class="dropdown-item notify-item">
                  <div class="notify-icon bg-warning">
                    <i class="mdi mdi-cart-outline"></i>
                  </div>
                  <p class="notify-details">
                    <b>Your order is placed</b
                    ><span class="text-muted"
                      >Dummy text of the printing and typesetting industry.</span
                    >
                  </p>
                </a>
              </div>
              <a
                href="javascript:void(0);"
                class="dropdown-item text-center notify-all text-primary"
              >
                View all <i class="fi-arrow-right"></i>
              </a>
            </div>
          </li> 
 
          <li class="dropdown notification-list list-inline-item">
            <router-link
              class="nav-link dropdown-toggle arrow-none waves-effect"
              :to="url+user.id"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
            
          </router-link>
          </li>  
          <li class="dropdown notification-list list-inline-item">
            <div class="dropdown notification-list nav-pro-img">
              <a
                class="dropdown-toggle nav-link arrow-none nav-user"
                data-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                 <img v-if="profile_pic == null"
                  src="https://bitwise.co.mz/assets/images/user-4.jpg"
                  alt="user"
                  class="rounded-circle"
                />
                <img v-else :src="ProfilePath+profile_pic"  class="rounded-circle" alt="">
                 <img v-else
                  :src="ProfilePath+userData.profile_pic"
                  alt="user"
                  class="rounded-circle"
                />
                
              </a>
              <div class="dropdown-menu dropdown-menu-right profile-dropdown">
               <router-link
                  class="dropdown-item text-white"
                  :to="{name:'profile', params:{id:userId}}"
                  >Perfil</router-link
                >
                <div class="dropdown-divider"></div>
                <a
                  class="dropdown-item text-danger"
                  href="javascript:void(0)"
                  @click="logout"
                  ><i class="mdi mdi-power text-danger"></i> Sair</a
                >
              </div>
            </div>
          </li>


        </ul>



        <ul class="list-inline mb-0 col-lg-9">
      
          <li class="d-none d-md-inline-block">
            <form role="search" class="app-search">
              <div class="form-group mb-0">
                <input type="text" class="form-control" placeholder="Search.." />
                <button type="submit"><i class="fa fa-search"></i></button>
              </div>
            </form>
          </li>
        </ul>
       
       
       </div>
       </nav>  -->

    <nav class="navbar navbar-expand-lg navbar-light bg-primary">
      <div class="container">
        <a class="navbar-brand" href="#" style="margin-left: -1em">
          <span class="d-block d-sm-none d-lg-block d-md-block rounded-circle">
            <img
              src="assets/images/Logo_Branco.png"
              class="text-danger"
              height="55"
              alt=""
            />
          </span>
        </a>

        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="list-inline mb-0">
            <li class="d-none d-md-inline-block">
              <form class="form-inline ml-0 ml-md-3 app-search">
                <div class="input-group input-group-sm">
                  <div class="form-group mb-0">
                    <input
                      class="form-control form-control-navbar"
                      type="search"
                      placeholder="Search..."
                      aria-label="Search"
                    />
                    <button type="submit">
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </form>
            </li>
          </ul>
          <ul
            class="ml-auto nav navbar-right list-inline mb-0"
            style="margin-right: -2em"
          >
            <li class="dropdown notification-list list-inline-item">
              <router-link
                class="nav-link dropdown-toggle arrow-none d-none d-sm-none d-lg-block d-md-block"
                to="/"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                Home
              </router-link>
            </li>

            <li class="dropdown notification-list list-inline-item">
              <router-link
                class="nav-link dropdown-toggle arrow-none waves-effect"
                to="/donate"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                Doar
              </router-link>
            </li>
            <li class="dropdown notification-list list-inline-item">
              <a
                v-if="totalUserOnline > 0"
                class="nav-link dropdown-toggle arrow-none d-none d-lg-block d-sm-none"
                href="javascript:void(0)"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <i class="fas fa-circle text-success"></i>
                Online: {{ totalUserOnline }}
              </a>
              <a
                v-else
                class="nav-link dropdown-toggle arrow-none d-none d-lg-block d-sm-none"
                href="javascript:void(0)"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <i class="fas fa-circle text-danger"></i>
                Online: {{ totalUserOnline }}
              </a>
            </li>

            <li class="dropdown notification-list list-inline-item">
              <a
                class="nav-link dropdown-toggle arrow-none waves-effect"
                href="javascript:void(0)"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                {{ provincia }}
              </a>
            </li>

            <li class="dropdown notification-list list-inline-item">
              <a
                class="nav-link dropdown-toggle arrow-none waves-effect"
                data-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <i class="mdi mdi-bell-outline noti-icon"></i>
                <span class="badge badge-pill badge-danger noti-icon-badge"
                  >3</span
                >
              </a>
              <div
                class="dropdown-menu dropdown-menu-right dropdown-menu-animated dropdown-menu-lg px-1"
              >
                <h6 class="dropdown-item-text">Notifications</h6>
                <div class="slimscroll notification-item-list">
                  <a
                    href="javascript:void(0);"
                    class="dropdown-item notify-item active"
                  >
                    <div class="notify-icon bg-success">
                      <i class="mdi mdi-cart-outline"></i>
                    </div>
                    <p class="notify-details">
                      <b>Your order is placed</b
                      ><span class="text-muted"
                        >Dummy text of the printing and typesetting
                        industry.</span
                      >
                    </p>
                  </a>
                  <a
                    href="javascript:void(0);"
                    class="dropdown-item notify-item"
                  >
                    <div class="notify-icon bg-danger">
                      <i class="mdi mdi-message-text-outline"></i>
                    </div>
                    <p class="notify-details">
                      <b>New Message received</b
                      ><span class="text-muted"
                        >You have 87 unread messages</span
                      >
                    </p>
                  </a>
                  <a
                    href="javascript:void(0);"
                    class="dropdown-item notify-item"
                  >
                    <div class="notify-icon bg-info">
                      <i class="mdi mdi-filter-outline"></i>
                    </div>
                    <p class="notify-details">
                      <b>Your item is shipped</b
                      ><span class="text-muted"
                        >It is a long established fact that a reader will</span
                      >
                    </p>
                  </a>
                  <a
                    href="javascript:void(0);"
                    class="dropdown-item notify-item"
                  >
                    <div class="notify-icon bg-success">
                      <i class="mdi mdi-message-text-outline"></i>
                    </div>
                    <p class="notify-details">
                      <b>New Message received</b
                      ><span class="text-muted"
                        >You have 87 unread messages</span
                      >
                    </p>
                  </a>
                  <a
                    href="javascript:void(0);"
                    class="dropdown-item notify-item"
                  >
                    <div class="notify-icon bg-warning">
                      <i class="mdi mdi-cart-outline"></i>
                    </div>
                    <p class="notify-details">
                      <b>Your order is placed</b
                      ><span class="text-muted"
                        >Dummy text of the printing and typesetting
                        industry.</span
                      >
                    </p>
                  </a>
                </div>
                <a
                  href="javascript:void(0);"
                  class="dropdown-item text-center notify-all text-primary"
                >
                  View all <i class="fi-arrow-right"></i>
                </a>
              </div>
            </li>

            <li class="dropdown notification-list list-inline-item">
              <router-link
                class="nav-link dropdown-toggle arrow-none waves-effect"
                :to="url + user.id"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
              </router-link>
            </li>
            <li class="dropdown notification-list list-inline-item">
              <div class="dropdown notification-list nav-pro-img">
                <a
                  class="dropdown-toggle nav-link arrow-none nav-user"
                  data-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="false"
                  aria-expanded="false"
                >
                  <img
                    v-if="profile_pic == null"
                    src="https://bitwise.co.mz/assets/images/user-4.jpg"
                    alt="user"
                    class="rounded-circle"
                  />
                  <!-- <img v-else :src="ProfilePath+profile_pic"  class="rounded-circle" alt=""> -->
                  <img
                    v-else
                    :src="profile_pic"
                    alt="user"
                    class="rounded-circle"
                  />
                </a>
                <div class="dropdown-menu dropdown-menu-right profile-dropdown">
                  <router-link
                    class="dropdown-item text-white"
                    :to="{ name: 'profile', params: { id: userId } }"
                    >Perfil</router-link
                  >
                  <div class="dropdown-divider"></div>
                  <a
                    class="dropdown-item text-danger"
                    href="javascript:void(0)"
                    @click="logout"
                    ><i class="mdi mdi-power text-danger"></i> Sair</a
                  >
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import store from "@/store";
import { mapActions, mapState } from "vuex";
import ProfileStoreModule from "@/pages/profile/ProfileStoreModule";
import axios from "axios";
import authHeader from "@/Api/AuthHeader";

const url = process.env.VUE_APP_API_URL;
export default {
  data() {
    return {
      user: store.state.auth.user,
      url: "/profile/",
      totalUserOnline: store.state.auth.onlineUser,
      provincia: store.state.auth.provincia,
      userId: store.state.auth.user.id,
      profile_pic: store.state.auth.user.profile_pic,
      userData: {},
      ProfilePath:
        "https://bitwise.bitwise.co.mz/bitwise/assets/upload_profile/",
      // ProfilePath: "http://127.0.0.1:8000/assets/upload_profile/",
      // profi: store.state.auth.imgState
    };
  },
  computed: {
    ...mapState("auth", {
      img: "imgState",
    }),
  },

  watch: {
    async img(changed) {
      if (changed) {
        const resp = await ProfileStoreModule.user();
        this.profile_pic = store.state.auth.user.profile_pic;
        store.dispatch("auth/updateimgstate", false);
      }
    },
  },

  methods: {
    ...mapActions({
      signOut: "auth/logout",
    }),
    async logout() {
      await axios
        .get(url + "logout", { headers: authHeader() })
        .then((response) => {
          if (response.data.status) {
            this.signOut();
            localStorage.removeItem("user");
            this.$router.push({ name: "login" });
          }
          console.log(response);
        });
    },
  },

  created() {
    if (store.state.auth.authenticated) {
      this.profile_pic =
        store.state.auth.user.profile_pic == null
          ? "https://bitwise.bitwise.co.mz/assets/images/avatar4.png"
          : "https://bitwise.bitwise.co.mz/bitwise/assets/upload_profile/" +
            store.state.auth.user.profile_pic;
    }
  },
};
</script>

<style scoped>
@media screen and (max-width: 680px) {
  #mobileTitle {
    visibility: hidden;

    display: none;
  }
}
</style>
