<template>
  <div class="authincation h-100">
    <div class="container h-100">
      <div class="col-lg-8 mx-auto" style="margin-top: 5em">
        <div class="row justify-content-center h-100 p-5 align-items-center">
          <div class="col-md-6">
            <div class="authincation-content">
              <div class="row no-gutters">
                <div class="col-xl-12">
                  <div class="auth-form">
                    <div class="text-center mb-2">
                      <img
                        src="assets/images/Logo_Branco.png"
                        width="120"
                        height="120"
                        alt=""
                      />
                    </div>
                    <!-- <h2 class="text-center mb-5" style="text-transform:uppercase">Jovens Unidos</h2> -->
                    <form action="index.html">
                      <div class="form-group">
                        <label><strong>Email ou Contacto</strong></label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="data.email"
                        />
                      </div>
                      <div class="form-group">
                        <label><strong>Senha</strong></label>
                        <input
                          type="password"
                          class="form-control"
                          v-model="data.password"
                        />
                      </div>
                      <div
                        class="form-row d-flex justify-content-between mt-4 mb-2"
                      >
                        <div class="form-group">
                          <div class="custom-control custom-checkbox ml-1">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="basic_checkbox_1"
                            />
                            <label
                              class="custom-control-label"
                              for="basic_checkbox_1"
                              >Lembrar-me</label
                            >
                          </div>
                        </div>
                        <div class="form-group">
                          <router-link to="/recover"
                            >Esqueceu a senha?
                          </router-link>
                        </div>
                      </div>
                      <div class="text-center">
                        <button
                          type="button"
                          class="btn-block"
                          style="
                            background-color: #e99239;
                            color: #fff;
                            border: none;
                            border-radius: 6px;
                            padding: 8px;
                          "
                          v-on:click="handleSubmitButton"
                        >
                          {{ loading == false ? "Acessar" : "Acessando..." }}
                        </button>
                      </div>
                    </form>
                    <div class="new-account mt-3">
                      <p>
                        Ainda nao fez a sua inscriçao?
                        <router-link style="color: #0093dd" to="/inscription"
                          >Clique aqui</router-link
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import toastr from "toastr";
import axios from "axios";
export default {
  data() {
    return {
      data: {
        // email: 'admin@ispocel.ac.mz',
        email: "aufderhar.vesta@example.net",
        password: "password",
      },
      loading: false,
    };
  },

  methods: {
    ...mapActions({
      signIn: "auth/login",
    }),

    async handleSubmitButton() {
      const url = process.env.VUE_APP_API_URL;

      this.loading = true;
      await axios
        .get("https://bitwise.bitwise.co.mz/sanctum/csrf-cookie")
        // .then((response) => {
        // axios.post('https://backend.bitwise.co.mz/api/login', this.data).then(({ data }) => {
        // await axios
        //   .get("http://127.0.0.1:8000/sanctum/csrf-cookie")
        .then((response) => {
          axios
            .post(url + "login", this.data)
            .then(({ data }) => {
              console.log(data.status);

              if (data.status == false) {
                toastr.error("Email ou senha incorrecto!!");
              } else {
                localStorage.setItem("user", JSON.stringify(data));
                toastr.success("Bem vindo!! acedeu com sucesso");
                this.signIn(data);
              }
            })
            .catch((response) => {
              console.log(response);
              toastr.error(
                "Erro de autenticacao verifique suas credenciais ou contacte o administrador" +
                  response.data.message
              );
            })
            .finally(() => {
              this.loading = false;
            });
        });
    },
  },
};
</script>
