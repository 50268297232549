<template>
  <div class="container">
    <div class="post" v-for="data in dataPost" :key="data.length">
      <div class="user-block">
        <img
          v-if="data.user.profile_pic == null"
          :src="imgUrl"
          alt=""
          class="img-circle img-bordered-sm"
        />
        <img
          v-else
          :src="Profilepath + data.user.profile_pic"
          alt=""
          class="img-circle img-bordered-sm"
        />
        <span class="username">
          <!-- <a href="">{{ data.user.username }}</a> -->
          <router-link
            :to="{ name: 'profile', params: { id: data.users_id } }"
            >{{ data.user.username }}</router-link
          >

          <!-- <a href="" class="float-right btn-tool"><i class="fas fa-times"></i></a> -->
        </span>
        <span class="description">
          Criado - {{ formatDates(data.created_at) }}
        </span>
      </div>

      <p v-if="dataPost[0].description != ''">
        {{ dataPost[0].description }}
      </p>

      <div
        class="row mb-3"
        v-if="dataPost[0].img != '' && dataPost[0].img != null"
      >
        <div class="col-sm-12">
          <!-- <img :src="path + dataPost[0].img" alt="" class="img-fluid" /> -->
          <img
            v-if="dataPost[0].img != null"
            :src="path + dataPost[0].img"
            alt=""
            class="img-fluid"
          />
        </div>
      </div>

      <div
        class="row mb-3"
        v-if="dataPost[0].video != '' && dataPost[0].video != null"
      >
        <div class="col-sm-12">
          <video
            v-if="dataPost[0].video != null"
            :src="pathVideo + dataPost[0].video"
            style="width: 100%"
            height="320"
            controls
          ></video>
        </div>
      </div>

      <div
        class="row mb-3"
        v-if="dataPost[0].doc_file != '' && dataPost[0].doc_file != null"
      >
        <div class="col-sm-12">
          <embed
            v-if="dataPost[0].doc_file != null"
            :src="pathDoc + dataPost[0].doc_file"
            style="width: 100%"
            height="320"
            controls
          />
        </div>
      </div>

      <p>
        <a href="" class="link-black text-sm mr-2"
          ><i class="fas fa-share mr-1"></i>Share</a
        >
        <span class="float-right">
          <router-link
            :to="{ name: 'comments', params: { id: 1 } }"
            class="link-black text-sm pr-4"
          >
            <i class="fas fa-comments mr-1"></i> Comentarios ({{
              commentsData.length
            }})
          </router-link>
        </span>
      </p>

      <!-- <form class="form-inline ml-0 app-search" id="form">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Recipient's username"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
          />
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button">
              Button
            </button>
          </div>
        </div>
      </form> -->
      <form @submit.prevent="sendComments" id="form">
        <input
          class="form-control"
          rows="5"
          style="resize: none"
          placeholder="Debater..."
          v-model="postData.description"
          id="input"
        />

        <button type="submit" class="btn btn-primary float-right">
          {{ loading == false ? "Debater" : "Enviando..." }}
        </button>
      </form>
    </div>

    <div class="post">
      <div
        class="post-content mb-1"
        v-for="comment in commentsData"
        :key="comment.length"
      >
        <div class="user-block">
          <!-- <img :src="imgUrl2" alt="" class="img-circle img-bordered-sm" /> -->
          <img
            v-if="comment.user.profile_pic == null"
            :src="imgUrl2"
            alt=""
            class="img-circle img-bordered-sm"
          />
          <img
            v-else
            :src="Profilepath + comment.user.profile_pic"
            alt=""
            class="img-circle img-bordered-sm"
          />
          <span class="username">
            <!-- <a href="">   {{ comment.user.username }}</a> -->
            <router-link
              :to="{ name: 'profile', params: { id: comment.user.id } }"
              >{{ comment.user.username }}</router-link
            >
          </span>
          <span class="description">{{ formatDates(comment.created_at) }}</span>
        </div>
        <p>
          {{ comment.description }}
        </p>
      </div>
    </div>
  </div>
</template>
  
<script>
import store from "@/store";
import { ref, watch } from "vue";
import toastr from "toastr";
import CommentsStoreModule from "./CommentsStoreModule";
export default {
  data() {
    return {
      imgUrl: "https://bitwise.co.mz/assets/images/avatar4.png",
      imgUrl2: "https://bitwise.co.mz/assets/images/avatar2.png",
      imgUrlPost: "https://bitwise.co.mz/assets/images/photo4.jpg",
      Profilepath:
        "https://bitwise.bitwise.co.mz/bitwise/assets/upload_profile/",
      pathVideo: "https://bitwise.bitwise.co.mz/bitwise/assets/upload_video/",
      pathDoc: "https://bitwise.bitwise.co.mz/bitwise/assets/upload_doc/",

      // Profilepath: "http://127.0.0.1:8000/assets/upload_profile/",
      // pathVideo: "http://127.0.0.1:8000/assets/upload_video/",
    };
  },
  setup() {
    const postData = {
      description: "",
      id_post: "",
      video: null,
    };

    const path = "https://bitwise.bitwise.co.mz/bitwise/assets/upload_img/";
    // const path = "http://127.0.0.1:8000/assets/upload_img/";
    const btnImage = ref(
      "https://bitwise.co.mz/assets/images/justicehammer.svg"
    );
    const dataPost = ref([]);
    const post_id = ref();
    const loading = ref(false);
    const commentsData = ref([]);

    async function getPostbyId() {
      post_id.value = this.$route.params.id;
      postData.id_post = this.$route.params.id;
      const res = await CommentsStoreModule.getPostbyId(post_id.value);
      console.log(res);
      dataPost.value = res.data.data;
    }

    async function getAllComments() {
      const res = await CommentsStoreModule.getPostComents(postData.id_post);
      console.log(res.data);
      // dataPost.value = res.data.data;
      commentsData.value = res.data;
    }

    async function sendComments() {
      loading.value = true;
      if (postData.description == "") {
        toastr.info("Por favor preencha o campo...");
        return;
      }

      console.log(postData);
      const res = await CommentsStoreModule.createComments(postData);
      if (res.status == true) {
        getAllComments();
        loading.value = false;
        postData.description = "";
      }
      console.log(res);
    }

    function formatDates(dateFormat) {
      const data = new Date(dateFormat);
      const day = data.getDate();
      const month = data.getMonth() + 1;
      const year = data.getFullYear();
      const hours = data.getHours();
      const minutes = data.getMinutes();
      const seconds = data.getSeconds();

      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    }

    return {
      formatDates,
      getPostbyId,
      postData,
      post_id,
      dataPost,
      path,
      btnImage,
      loading,
      sendComments,
      getAllComments,
      commentsData,
    };
  },
  created() {
    this.getPostbyId();
    this.getAllComments();
  },
};
</script>

<style scoped>
#form {
  background-color: rgba(0, 0, 0, 0.15);
  padding: 0.25rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  height: 3rem;
  box-sizing: border-box;
  backdrop-filter: blur(10px);
}

#input {
  margin: auto;
  border: none;
  padding: 0 1rem;
  width: 51%;
  margin: 0.25rem;
}

#input:focus {
  outline: none;
}

#form > button {
  border: none;
  padding: 0 1rem;
  margin: 0.25rem;
  border-radius: 3px;
  outline: none;
  color: #fff;
}

#messages {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#messages > li {
  padding: 0.5rem 1rem;
}

#messages > li:ntn-child(odd) {
  background: #efefef;
}
.post {
  color: #666;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.post:last-of-type {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}
.post .user-block {
  margin-bottom: 15px;
  width: 100%;
}
.post .row {
  width: 100%;
}

.img-circle {
  border-radius: 50%;
}

.img-bordered-sm {
  border: 2px solid #adb5bd;
  padding: 2px;
}

.user-block {
  float: left;
}
.user-block img {
  float: left;
  height: 40px;
  width: 40px;
}
.user-block .comment,
.user-block .description,
.user-block .username {
  display: block;
  margin-left: 50px;
}
.user-block .username {
  font-size: 16px;
  font-weight: 600;
  margin-top: -1px;
}
.user-block .description {
  color: #6c757d;
  font-size: 13px;
  margin-top: -3px;
}

.link-black {
  color: #6c757d;
}
.link-black:focus,
.link-black:hover {
  color: #e6e8ea;
}

.post-content {
  font-size: 13px;
  line-height: 18px;
  color: #ffffff;
  display: block;
  background: #2e304a;
  border-radius: 6px;
  padding: 15px 20px 20px 20px;
}
</style>
 
