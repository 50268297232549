<template>
  <div class="container-fluid" v-if="page_type == 'A'">
    <div class="page-title-box">
      <div class="row align-items-center">
        <div class="col-sm-6">
          <h4 class="page-title">Dashboard</h4>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-right">
            <li class="breadcrumb-item"><a href="#">Jassa</a></li>
            <li class="breadcrumb-item active">Dashboard</li>
          </ol>
        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- end page-title -->
    <div class="row">
      <div class="col-sm-6 col-xl-3">
        <div class="card">
          <div class="card-heading p-4">
            <div class="mini-stat-icon float-right">
              <i class="mdi mdi-cube-outline bg-primary text-white"></i>
            </div>
            <div>
              <h5 class="font-16">Active Sessions</h5>
            </div>
            <h3 class="mt-4">43,225</h3>
            <div class="progress mt-4" style="height: 4px">
              <div
                class="progress-bar bg-primary"
                role="progressbar"
                style="width: 75%"
                aria-valuenow="75"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p class="text-muted mt-2 mb-0">
              Previous period<span class="float-right">75%</span>
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xl-3">
        <div class="card">
          <div class="card-heading p-4">
            <div class="mini-stat-icon float-right">
              <i class="mdi mdi-briefcase-check bg-success text-white"></i>
            </div>
            <div>
              <h5 class="font-16">Total Revenue</h5>
            </div>
            <h3 class="mt-4">$73,265</h3>
            <div class="progress mt-4" style="height: 4px">
              <div
                class="progress-bar bg-success"
                role="progressbar"
                style="width: 88%"
                aria-valuenow="88"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p class="text-muted mt-2 mb-0">
              Previous period<span class="float-right">88%</span>
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xl-3">
        <div class="card">
          <div class="card-heading p-4">
            <div class="mini-stat-icon float-right">
              <i class="mdi mdi-tag-text-outline bg-warning text-white"></i>
            </div>
            <div>
              <h5 class="font-16">Average Price</h5>
            </div>
            <h3 class="mt-4">447</h3>
            <div class="progress mt-4" style="height: 4px">
              <div
                class="progress-bar bg-warning"
                role="progressbar"
                style="width: 68%"
                aria-valuenow="68"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p class="text-muted mt-2 mb-0">
              Previous period<span class="float-right">68%</span>
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xl-3">
        <div class="card">
          <div class="card-heading p-4">
            <div class="mini-stat-icon float-right">
              <i class="mdi mdi-buffer bg-danger text-white"></i>
            </div>
            <div>
              <h5 class="font-16">Add to Card</h5>
            </div>
            <h3 class="mt-4">86%</h3>
            <div class="progress mt-4" style="height: 4px">
              <div
                class="progress-bar bg-danger"
                role="progressbar"
                style="width: 82%"
                aria-valuenow="82"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p class="text-muted mt-2 mb-0">
              Previous period<span class="float-right">82%</span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- end row -->
    <div class="row">
      <div class="col-xl-4">
        <div class="card m-b-30">
          <div class="card-body">
            <h4 class="mt-0 header-title mb-4">Recent Activity</h4>
            <ol class="activity-feed mb-0">
              <li class="feed-item">
                <div class="feed-item-list">
                  <p class="text-muted mb-1">Now</p>
                  <p class="font-15 mt-0 mb-0">
                    Jassa magna Jassa, risus posted a new article:
                    <b class="text-primary">Forget UX Rowland</b>
                  </p>
                </div>
              </li>
              <li class="feed-item">
                <p class="text-muted mb-1">Yesterday</p>
                <p class="font-15 mt-0 mb-0">
                  Jassa posted a new article:
                  <b class="text-primary">Designer Alex</b>
                </p>
              </li>
              <li class="feed-item">
                <p class="text-muted mb-1">2:30PM</p>
                <p class="font-15 mt-0 mb-0">
                  Jassa, Jassa, Jassa Commented
                  <b class="text-primary"> Developer Moreno</b>
                </p>
              </li>
              <li class="feed-item pb-1">
                <p class="text-muted mb-1">12:48 PM</p>
                <p class="font-15 mt-0 mb-2">
                  Jassa, Jassa Commented <b class="text-primary">UX Murphy</b>
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" v-else>
    <div class="col-md-9 col-sm-8">
      <swiper
        class="d-none d-md-block d-sm-block d-lg-block d-xl-block mb-4 mt-2"
        style="display: relative; height: 300px; margin-left: -2em"
        pager="true"
        :modules="modules"
        :autoplay="{
          delay: 2500,
          disableOnInteraction: false,
        }"
        :loop="true"
        :pagination="true"
      >
        <swiper-slide class="">
          <img src="assets/images/slide-1.jpeg" alt="" class="swiper-lazy" />
        </swiper-slide>

        <swiper-slide class="">
          <img src="assets/images/slide-12.jpeg" alt="" class="swiper-lazy" />
        </swiper-slide>
        <swiper-slide>
          <img src="assets/images/slide-10.jpg" alt="" />
        </swiper-slide>
        <swiper-slide>
          <img src="assets/images/slide-11.jpg" alt="" />
        </swiper-slide>

        <swiper-slide>
          <img src="assets/images/slide-4.jpeg" alt="" />
        </swiper-slide>
      </swiper>

      <div
        class="card col-lg-12 col-sm-12"
        style="margin-left: -2em"
        v-if="page_type == 'M' || page_type == 'V'"
      >
        <div class="card-body">
          <form @submit.prevent="submitData">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <textarea
                    class="form-control"
                    rows="5"
                    style="resize: none"
                    placeholder="Começar um debate..."
                    v-model="postData.description"
                  ></textarea>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-group">
                <label for="photoUpload">
                  <span id="foto"
                    ><img
                      :src="uploadImage"
                      width="25"
                      height="25"
                      alt=""
                      style="margin-left: 5px; margin-right: 5px"
                    />
                  </span>

                  <input
                    type="file"
                    id="photoUpload"
                    @change="onChange"
                    hidden
                  />
                </label>
              </div>

              <div class="form-group">
                <label for="photoUploadVideo">
                  <span id="foto"
                    ><img
                      :src="VideoImage"
                      width="25"
                      height="25"
                      alt=""
                      style="margin-left: 5px; margin-right: 5px"
                    />
                  </span>

                  <input
                    type="file"
                    id="photoUploadVideo"
                    @change="onChangeVideo"
                    hidden
                  />
                </label>
              </div>

              <div class="form-group">
                <label for="photoUplaodDoc">
                  <span id="foto"
                    ><img
                      :src="filesSvg"
                      width="25"
                      height="25"
                      alt=""
                      style="margin-left: 5px; margin-right: 5px"
                  /></span>
                  <input
                    type="file"
                    @change="onChangeDoc"
                    id="photoUplaodDoc"
                    ref="photoUplaodDoc"
                    hidden
                  />
                </label>
              </div>
            </div>

            <!-- Image Preview -->
            <div class="user-block mb-1" v-if="imagemTempView != null">
              <img :src="imagemTempView" alt="" class="img-bordered-sm" />
            </div>

            <div
              class="user-block mb-1"
              v-if="VideoPreview != null || VideoPreview == ''"
            >
              <video
                :src="VideoPreview"
                class="img-bordered-sm"
                width="180"
                height="180"
                controls
              ></video>
            </div>

            <div class="user-block mb-1" v-if="docPreview">
              <embed
                :src="docPreview"
                v-if="ispfd"
                type="application/pdf"
                width="100%"
                height="300px"
              />
            </div>
            <div class="user-block mb-1" v-if="isWord">
              <span
                ><i class="fas fa-file-word text-primary"></i>
                {{ postData.docName }}</span
              >
            </div>

            <!-- {{ postData.img}} -->
            <!-- End -->
            <div class="modal-footer col-lg-12 col-md-12 col-sm-12">
              <button type="submit" class="btn btn-primary float-right">
                {{ loading == false ? "Publicar" : "Publicando..." }}
                <!-- <img :src="btnImage" width="20" height="20" alt="" /> -->
              </button>
            </div>
            <div v-if="progress > 0">Carregando {{ progress }}%</div>
          </form>
        </div>
        <div class="row">
          <!-- <ul class="list-inline menu-left mb-0">
      
        <li class="d-block d-sm-none d-lg-block d-md-block">
            <h1>jheadk</h1>
        </li>
      </ul> -->
          <!-- .d-none .d-sm-block .d-md-none -->
          <!-- <div class="col-lg-2 col-md-2 col-sm-12 mb-3 d-none d-sm-block d-md-block d-lg-none" style="border-left: 1px solid #ddd;">
      <span class="mb-4" v-if="totalUsersOnline > 0"><i class="fas fa-circle text-success"></i>&nbsp;online: &nbsp;{{totalUsersOnline}} </span> 
      <span class="mb-4" v-else><i class="fas fa-circle text-danger"></i>&nbsp; online: &nbsp; 0 </span> 
    </div> -->
        </div>
      </div>

      <!-- .d-none .d-sm-block .d-md-none -->
      <div
        class="col-lg-2 col-md-2 col-sm-12 mb-3 d-none d-block d-block d-md-none"
      >
        <span class="mb-4" v-if="totalUsersOnline > 0"
          ><i class="fas fa-circle text-success"></i>&nbsp;Online: &nbsp;{{
            totalUsersOnline
          }}
        </span>
        <span class="mb-4" v-else
          ><i class="fas fa-circle text-danger"></i>&nbsp; Online: &nbsp; 0
        </span>
      </div>

      <!-- <div
      class="alert alert-success alert-dismissible fade show"
      role="alert"
      v-if="showAlert"
    >
      <strong>Debate aberto com sucesso!</strong>
      <router-link
        :to="{ name: 'check_post', params: { id: post_id } }"
        id="postCheck"
      >
        clique aqui para visualizar a sua publicação.</router-link
      >
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div> -->

      <feedVue :dataPost="DataPost" :Datalikes="DataPostLike" />
    </div>

    <div class="col-lg-3 col-xxl-3 col-lg-3 mt-2 float-right">
      <!-- Profile Image -->

      <div
        class="row d-none d-lg-block d-sm-none"
        style="height: 200px; margin-right: -3.5em"
      >
        <div class="col-lg-12 col-sm-8">
          <div class="card card-primary card-outline">
            <div class="card-body box-profile">
              <router-link :to="{ name: 'profile', params: { id: userId } }">
                <div class="text-center">
                  <img
                    :src="ImgProfile"
                    class="profile-user-img img-circle img-fluid"
                  />
                </div>
                <h3 class="profile-username text-center">
                  {{ username }}
                </h3>
              </router-link>
              <!-- <p class="text-muted text-center"> Engineer</p>  -->
            </div>
            <!-- /.card-body -->
          </div>
        </div>
      </div>

      <div class="row d-none d-lg-block d-sm-none" style="margin-right: -3.5em">
        <div class="col-lg-12 col-sm-8">
          <div class="card card-primary card-outline">
            <div class="card-body box-profile">
              <li><a href="#">Meus videos (10)</a></li>
              <li><a href="#">Minhas Fotos (16)</a></li>
              <li><a href="#">Documentos Partilhados (6)</a></li>
            </div>
          </div>
        </div>
      </div>

      <div class="row" style="margin-right: -3.5em">
        <div class="col-lg-12 col-sm-8">
          <div class="card card-primary card-outline">
            <div class="card-body box-profile">
              <li><a href="#">Sobre</a></li>
              <li><a href="#">Acessibilidade</a></li>
              <li><a href="#">Diretirzes da Comunidade</a></li>
              <li><a href="#">Termos e Privancidade</a></li>
              <li><a href="#">Publicidades</a></li>
              <li><a href="#">Central de Seguranca</a></li>
              <li><a href="#">Dúvidas?</a></li>
              <li><a href="#">Ajuda?</a></li>
              <router-link to="/" class="mx-auto" style="text-align: center">
                <span class="text-white text-center">
                  <img
                    src="assets/images/logoapp.png"
                    width="40"
                    height="40"
                    alt=""
                  />
                  Jovens Unidos &copy; 2023
                </span>
              </router-link>
              <!-- <p class="text-muted text-center"> Engineer</p>  -->
            </div>
            <!-- /.card-body -->
          </div>
        </div>
      </div>
      <!-- /.card -->
    </div>

    <!-- <div class="col-lg-2 col-md-2 col-sm-12 mb-3 d-none d-md-block d-sm-block d-lg-block d-xl-block " style="border-left: 1px solid #ddd;">
      <span class="mb-4" v-if="totalUsersOnline > 0"><i class="fas fa-circle text-success"></i>&nbsp;online: &nbsp;{{totalUsersOnline}} </span> 
      <span class="mb-4" v-else><i class="fas fa-circle text-danger"></i>&nbsp; online: &nbsp; 0 </span> 
    </div> -->
  </div>
</template>

<script>
import store from "@/store";
import feedVue from "./feed.vue";
import { ref, watch, computed } from "vue";
import toastr from "toastr";
import PostStoreModule from "./PostStoreModule";
import { mapState, useStore, mapActions } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue";
import MyClientFooter from "../layouts/MyClientFooter.vue";
import authHeader from "@/Api/AuthHeader";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";

import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core";
import axios from "axios";

SwiperCore.use([Pagination, Navigation]);

export default {
  components: {
    feedVue,
    Swiper,
    SwiperSlide,
    MyClientFooter,
  },

  // data() {
  //   return {
  //     page_type: null,
  //     uploadImage: "http://localhost:8080/assets/images/photo.svg",
  //     btnImage: "http://localhost:8080/assets/images/justicehammer.svg",
  //   };
  // },

  setup() {
    const postData = {
      description: "",
      img: null,
      video: null,
      doc: null,
      docName: null,
    };

    // const store = useStore()

    const page_type = ref(null);

    const uploadImage = ref(
      "https://bitwise.bitwise.co.mz/assets/images/uploadImg.svg"
    );
    const VideoImage = ref(
      "https://bitwise.bitwise.co.mz/assets/images/video.svg"
    );
    const filesSvg = "https://bitwise.bitwise.co.mz/assets/images/files.svg";
    // const uploadImage = ref(" http://localhost:8080/assets/images/uploadImg.svg");
    // const VideoImage = ref(" http://localhost:8080/assets/images/video.svg");
    const btnImage = ref(
      "https://bitwise.bitwise.co.mz/assets/images/justicehammer.svg"
    );
    const path = "https://bitwise.bitwise.co.mz/bitwise/assets/upload_img/";
    // const ProfilePath =  "http://127.0.0.1:8000/assets/upload_profile/";
    const ProfilePath =
      "https://bitwise.bitwise.co.mz/bitwise/assets/upload_profile/";

    // page_type.value = store.state.auth.user.page_type;
    const url = process.env.VUE_APP_API_URL;

    const loading = ref(false);
    const showAlert = ref(false);
    const imagemTempView = ref(null);
    const VideoPreview = ref(null);
    const docPreview = ref(null);
    const post_id = ref(null);
    const DataPost = ref([]);
    const DataPostLike = ref([]);
    const totalUsersOnline = ref();
    const progress = ref(0);
    const profilePicPreview = ref(null);
    const username = store.state.auth.user.username;
    const ImgProfile = store.state.auth.user.profile_pic;
    const userId = store.state.auth.user.id;
    const readAsDataURL = ref(null);
    const photoUplaodDoc = ref(null);
    const isWord = ref(false);
    const ispfd = ref(false);

    async function submitData() {
      loading.value = true;
      let data = new FormData();

      if (
        postData.description == "" &&
        postData.img == null &&
        postData.video == null &&
        postData.doc == null
      ) {
        toastr.warning("Por favor preencha um dos campos");
        loading.value = false;
        return;
      }
      data.append("description", postData.description);
      data.append("img", postData.img);
      data.append("video", postData.video);
      data.append("doc_file", postData.doc);
      // const res = await PostStoreModule.createPost(data);

      const config = {
        onUploadProgress: (progressEvent) => {
          progress.value = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
      };

      axios
        .post(
          url + "post",
          data,
          {
            headers: authHeader(),
            "content-type": "multipart/form-data",
          },
          config
        )
        .then((response) => {
          getPosts();
          clearField();
          setTimeout(() => {
            progress.value = 0;
          }, 3000);

          loading.value = false;
        })
        .catch((error) => {});

      // if (res.status) {
      //   toastr.success(res.message);
      //   post_id.value = res.post_id;
      //   showAlert.value = true;
      //   getPosts();
      //   clearField();
      // }
    }

    function clearField() {
      postData.description = "";
      postData.img = "";
      imagemTempView.value = null;
      VideoPreview.value = null;
      postData.video = "";
      postData.doc = "";
      docPreview.value = null;
      photoUplaodDoc.value.value = null;
    }

    function onChange(e) {
      if (VideoPreview.value != null || docPreview.value != null) {
        postData.video = null;
        postData.doc = null;
        VideoPreview.value = null;
        docPreview.value = null;
        photoUplaodDoc.value.value = null;
      }
      console.log(e.target.files[0]);
      postData.img = e.target.files[0];
      const fileType = postData.img.type;

      if (fileType.includes("image")) {
        // if(postData.video.size > 5242880 ){
        //   toastr.info("O tamanho do video excede o limite permitido(5MB).")
        //   return;
        // }else{
        imagemTempView.value = URL.createObjectURL(postData.img);

        // }
      } else {
        toastr.info("Tipo de arquivo inválido");
      }
    }

    function onChangeVideo(e) {
      // console.log(e.target.files[0]);

      if (imagemTempView.value != null || docPreview.value != null) {
        postData.img = null;
        postData.doc = null;
        docPreview.value = null;
        imagemTempView.value = null;
        photoUplaodDoc.value.value = null;
      }
      postData.video = e.target.files[0];
      const fileType = postData.video.type;

      if (fileType.includes("video")) {
        // if(postData.video.size > 5242880 ){
        //   toastr.info("O tamanho do video excede o limite permitido(5MB).")
        //   return;
        // }else{
        VideoPreview.value = URL.createObjectURL(postData.video);

        // }

        console.log(postData.video.size);
      } else {
        toastr.info("Tipo de arquivo inválido");
      }
    }

    function onChangeDoc(e) {
      // console.log(e.target.files[0]);
      if (imagemTempView.value != null) {
        postData.img = "";
        imagemTempView.value = null;
        // toastr.info("Tipo de arquivo inválido")
      }

      if (VideoPreview.value != null) {
        postData.video = "";
        VideoPreview.value = null;
      }

      postData.doc = e.target.files[0];
      postData.docName = e.target.files[0].name;

      previewDocument();
      const fileType = postData.doc.type;
      console.log(e.target.files[0]);

      if (fileType.includes("pdf")) {
        ispfd.value = true;
        isWord.value = false;

        // if(postData.video.size > 5242880 ){
        //   toastr.info("O tamanho do video excede o limite permitido(5MB).")
        //   return;
        // }else{
        // docPreview.value = URL.createObjectURL(postData.doc);

        // }
        previewDocument();
      } else if (fileType.includes("word")) {
        ispfd.value = false;
        isWord.value = true;
      } else {
        toastr.info("Tipo de arquivo inválido");
      }
    }

    function previewDocument() {
      if (postData.doc) {
        const reader = new FileReader();

        reader.onload = (event) => {
          docPreview.value = event.target.result;
        };

        reader.readAsDataURL(postData.doc);
      }
    }

    async function getPosts() {
      const res = await PostStoreModule.getPosts();
      console.log(res.data);
      DataPost.value = res.data.posts;
      totalUsersOnline.value = res.data.totalUserOnline;
      store.dispatch("auth/totalUserOnline", res.data.totalUserOnline);
      store.dispatch("auth/postProvincia", res.data.provincia);
    }

    async function getAllLikes() {
      const res = await PostStoreModule.getAllLikes();
      DataPostLike.value = res.data;
    }

    watch(showAlert, (first, second) => {
      setTimeout(() => {
        showAlert.value = false;
      }, 5000);
    });

    //  ...mapActions('auth', ['likedPost']);

    watch(
      () => store.state.auth.userlikedPost,
      () => {
        if (store.state.auth.userlikedPost == true) {
          getPosts();
          // getAllLikes();
          store.dispatch("auth/likedPost", false);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    return {
      modules: [Autoplay, Pagination, Navigation],

      postData,
      loading,
      submitData,
      page_type,
      progress,
      btnImage,
      uploadImage,
      onChange,
      imagemTempView,
      readAsDataURL,
      path,
      previewDocument,
      clearField,
      showAlert,
      post_id,
      getPosts,
      DataPost,
      getAllLikes,
      DataPostLike,
      totalUsersOnline,
      VideoImage,
      userId,
      onChangeVideo,
      VideoPreview,
      profilePicPreview,
      ImgProfile,
      username,
      ProfilePath,
      filesSvg,
      docPreview,
      onChangeDoc,
      photoUplaodDoc,
      isWord,
      ispfd,
    };
  },
  //  ...mapActions({
  //       LikedPost: 'auth/likedPost'
  //     }),
  created() {
    if (store.state.auth.authenticated) {
      this.ImgProfile =
        store.state.auth.user.profile_pic == null
          ? "https://bitwise.bitwise.co.mz/assets/images/avatar4.png"
          : "https://bitwise.bitwise.co.mz/bitwise/assets/upload_profile/" +
            store.state.auth.user.profile_pic;
    }

    this.page_type = store.state.auth.user.page_type;
    console.log(store.state.auth.user.page_type);
    // this.userId = store.state.auth.user.id;
    this.getPosts();
    this.getAllLikes();
  },
};
</script>

<style scoped>
#foto {
  background: #2e304a;
  width: 40px;
  height: 40px;
  cursor: pointer;
  color: #fff;
  box-shadow: 0px 12px 4px 0px rgba(0, 0, 0, 0.1);
}

#foto:hover {
  background: #f5f1f1;
  width: 40px;
  height: 40px;
  cursor: pointer;
  color: #27538d;
}

#foto {
  margin-left: 15px;
  padding: 10px 10px;
  border-radius: 8px;
}

.user-block {
  float: left;
}
.user-block img {
  float: left;
  height: 100px;
  width: 100px;
  object-fit: cover;
}

#postCheck {
  text-decoration: underline;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide.teste .test {
  position: absolute;
  top: 200px;
  left: 10em;
  width: 50%;
  height: 200px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-user-img {
  border: 3px solid #adb5bd;
  margin: 0 auto;
  padding: 3px;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.img-circle {
  border-radius: 50%;
}

.img-bordered-sm {
  border: 2px solid #adb5bd;
  padding: 2px;
}

.footer-custom li {
  display: block;
  margin-top: 5px;
  margin-left: 6px;
}
</style>
