<template>
  <div id="wrapper">
    <MyNavbar v-if="page_type == 'V'" />
    <AdminNav v-if="page_type == 'A'" />
    <MySideBar v-if="page_type == 'A'" />

    <div v-if="page_type == 'A'">
      <div class="content-page">
        <div class="content">
          <RouterView></RouterView>
        </div>
      </div>
      <MyFooter />
    </div>

    <div v-else>
      <div class="content-page col-lg-9 col-sm-12 col-md-12">
        <div class="container">
          <div class="content pt-3">
            <RouterView></RouterView>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import MyNavbar from "./MyNavbar.vue";
import AdminNav from "./AdminNav.vue";
import MySideBar from "./MySideBar.vue";
import MyFooter from "./MyFooter.vue";
import MyClientFooter from "./MyClientFooter.vue";
import store from "@/store";

const page_type = store.state.auth.user.page_type;

// window.location.href = "/dashboard"
// console.log("main",page_type);
</script>