import axios from "axios";
import authHeader from "@/Api/AuthHeader";
const url = process.env.VUE_APP_API_URL;
export default {
  namespaced: true,

  async createMunicipe(FormData) {
    try {
      const response = await axios.post(url+"municipe", FormData, {
        headers: authHeader(),
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  async getProvincias() {
    try {
      const response = await axios.get(url+"provinces");

      return response.data;
    } catch (error) {
      return error;
    }
  },
};
