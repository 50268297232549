import axios from "axios";
import authHeader from "@/Api/AuthHeader";
const url = process.env.VUE_APP_API_URL;

export default {
  namespaced: true,

  async createComments(FormData) {
    try {
      const response = await axios.post(url+"createComments", FormData, {
        headers: authHeader(),
        "content-type": "multipart/form-data",
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

 

  async getPosts() {
    try {
      const res = await axios.get(url+"getPosts", {
        headers: authHeader(),
      });
      return res;
    } catch (error) {
      return error;
    }
  },

  async getPostComents(id) {
    try {
      const res = await axios.get(url+"getPostComents/" + id, {
        headers: authHeader(),
      });
      return res;
    } catch (error) {
      return error;
    }
  },

  async getPostbyId(id) {
    try {
      const res = await axios.get(url+"showPost/" + id, {
        headers: authHeader(),
      });
      return res;
    } catch (error) {
      return error;
    }
  },
};
