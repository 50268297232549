<template>
  <p>
    <a
      href="javascript:void(0)"
      class="link-black text-sm mr-2"
      v-if="user_auth_like_post()"
      ><i class="fas fa-thumbs-up mr-1 text-primary"></i>Gostado por
      {{ LikesPost.length }} Pessoas</a
    >

    <a
      href="javascript:void(0)"
      class="link-black text-sm mr-2"
      @click="like(id)"
      v-else
      ><i class="fas fa-thumbs-up mr-1"></i>Gosto</a
    >

    <a
      href="javascript:void(0)"
        v-if="user_auth_Dislike_post()"
      class="link-black text-sm mr-2"
      ><i class="fas fa-thumbs-down mr-1 text-primary"></i>Nao Gostado por {{DislikesPost.length}} pessoas</a
    >
    <a v-else
      href="javascript:void(0)"
      @click="dislike(id)"
      class="link-black text-sm mr-2"
      ><i class="fas fa-thumbs-down mr-1"></i>Nao Gosto</a
    >

    <a href="" class="link-black text-sm mr-2"
      ><i class="fas fa-share mr-1"></i>Share</a
    >
    <!-- <span class="float-right"> -->
    <!-- <router-link
            :to="{ name: 'comments', params: { id: data.id } }"
            class="link-black text-sm pr-4"
          >
            <i class="far-fa-comments mr-1"></i> Comentarios)
          </router-link>
        </span> -->
  </p>
</template>

<script>
import store from "@/store";
import PostStoreModule from "./PostStoreModule";
export default {
  props: ["id"],
  data() {
    return {
      LikesPost: [],
      DislikesPost: [],
      liker: [],
      disliker: [], 
      user_id: store.state.auth.user.id,
    };
  },

  computed: {
    likers() {
      this.LikesPost.forEach((element) => {
        this.liker.push(element.users_id);
      });

      return this.liker;
    },

    dislikers() {
      this.DislikesPost.forEach((element) => {
        this.disliker.push(element.users_id);
      });

      return this.disliker;
    },
  },

  methods: {
    async getAllLIkesBypost() {
      const res = await PostStoreModule.getLikesByPostbyId(this.id);
      this.LikesPost = res.data;
    },

    async getAllDislikesBypost() {
      const res = await PostStoreModule.getDislikesByPostbyId(this.id);

       this.DislikesPost = res.data;
    },

    user_auth_like_post() {
      var check_index = this.likers.indexOf(this.user_id);

      if (check_index === -1) {
        return false;
      } else {
        return true;
      }
    },

     user_auth_Dislike_post() {
      var check_index = this.dislikers.indexOf(this.user_id);

      if (check_index === -1) {
        return false;
      } else {
        return true;
      }
    },

    async like(post_id) {
      const res = await PostStoreModule.createLike(post_id);
      this.getAllDislikesBypost();
      this.getAllLIkesBypost();
      

     },

    async dislike(post_id) {
      const res = await PostStoreModule.createDislike(post_id);
      this.getAllLIkesBypost();
      this.getAllDislikesBypost();


     },
  },

  created() {
    this.getAllLIkesBypost();
    this.getAllDislikesBypost();
  },
};
</script>